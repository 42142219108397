import {computed, Injectable, signal} from '@angular/core';
import {DvbDateUtil, START_OF_TYPE} from '@dv/shared/code';
import moment from 'moment';
import type {DisplayMode} from '../personalplanung/personalplanung-display-mode';

@Injectable({
    providedIn: 'root',
})
export class PersonalTimelineStore {

    public displayMode = signal<DisplayMode>('week');
    public selectedDate = signal<moment.Moment>(DvbDateUtil.today(), {equal: DvbDateUtil.isMomentEquals});

    public unit = computed(() => START_OF_TYPE[this.displayMode()]);
    public startDate = computed(() => moment(this.selectedDate()).startOf(this.unit()),
        {equal: DvbDateUtil.isMomentEquals});
    public endDate = computed(() => moment(this.selectedDate()).endOf(this.unit()),
        {equal: DvbDateUtil.isMomentEquals});

    public displayRange = computed(() => {
        const start = this.startDate();
        const end = this.endDate();
        const displayMode = this.displayMode();

        if (displayMode === 'day') {
            return start.format('D.M.YYYY');
        }

        return `${start.format('D.M.YYYY')} - ${end.format('D.M.YYYY')}`;
    });
}
