@if (store.kinderOrt(); as kinderOrt) {
    <div class="personalplanung-header" #personalplanungHeader>
        <div class="menu-left">
            <dv-personalplanung-filter
                [displayMode]="timelineStore.displayMode()"
                (displayModeChange)="timelineStore.displayMode.set($event)"
                [selectedDate]="timelineStore.selectedDate()"
                (selectedDateChange)="timelineStore.selectedDate.set($event)"
                [fraktionFilter]="store.fraktionFilterOptions()"
                (filteredFraktionenChange)="store.selectedFraktionen.set($event)"
                [filterBedarfsRelevanteTermine]="store.filterBedarfsRelevanteTermine()"
                (filterBedarfsRelevanteTermineChange)="store.filterBedarfsRelevanteTermine.set($event)">
            </dv-personalplanung-filter>
        </div>
        <dvlib-spinner fillSpace [show]="store.isLoading()"/>
        <div class="menu-right">
            @if (!store.readonly()) {
                <div class="actions">
                    <button *dvlibRequirePermission="'work_time_controlling:kita_convert_planned_to_actual:' + kinderOrt.id"
                            dvlibButton="link-button"
                            iconPosition="left"
                            (click)="store.saveControllingDataDialogOpen.set(true)"
                            icon="clock">
                        {{ 'PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING' | translate }}
                    </button>
                    <button dvlibButton="link-button"
                            iconPosition="left"
                            (click)="copyStore.copyDialogOpen.set(true)"
                            icon="copy">
                        {{ 'PERSONAL.ZUWEISUNG.COPY' | translate }}
                    </button>
                    <button dvlibButton="link-button"
                            iconPosition="left"
                            (click)="emailStore.dialogOpen.set(true)"
                            icon="mailOpen">
                        {{ 'PERSONAL.ZUWEISUNG.EMAIL' | translate }}
                    </button>
                    <a href
                       [uiSref]="'base.report.category.personal.personalplanung'"
                       [uiParams]="{gueltigAb: timelineStore.startDate(), gueltigBis: timelineStore.endDate()}">
                        <dvlib-icon iconName="download" class="smaller-abstand-right"></dvlib-icon>
                        <span translate="PERSONAL.ZUWEISUNG.PERSONALPLAN_GENERIEREN"></span>
                    </a>
                </div>
            }
            <dv-zuweisbare-dienste
                [dienste]="store.dienste()"
                [readonly]="store.readonly()"
                (dragActive)="store.dragActive.set($event)">
            </dv-zuweisbare-dienste>
        </div>
    </div>
}

<dv-timeline-calendar
    [groups]="store.groups"
    [availableResources]="(store.angestellte$ | async) || []"
    [startDate]="timelineStore.startDate()"
    [endDate]="timelineStore.endDate()"
    [startHour]="store.arbeitszeitVon()"
    [endHour]="store.arbeitszeitBis()"
    [dragMode]="store.dragActive()"
    [layerConfig]="store.timelineLayerConfig()"
    [selectedDate]="timelineStore.selectedDate()"
    [calendarTranslation]="calendarTranslation"
    [showDayInfo]="true"
    [readonly]="store.readonly()"
    [dayInfo]="(store.tagesinfos$ | async) || []"
    [stickyHeaderPos]="calendarStickyTopPos()"
    [scrollBeforeSticky]="calendarScrollBeforeSticky()"
    (sortGroup)="store.sortPersonal($event)"
    (resourceDrop)="store.assignDienstOrTermin($event)"
    (resizeComplete)="store.updateZuweisungOrTermin($event)"
    (resizeEvent)="store.resizing($event)"
    (resourceAdd)="store.resourceAdd($event)"
    (resourceRemove)="store.deleteZuweisungen($event)"
    (deleteEvent)="store.deleteZuweisungOrTermin($event)"
    (editEvent)="store.editZuweisungOrTermin($event)"
    (editDayInfoEvent)="store.editDayInfo($event)"
    (selectDate)="selectDate($event)">
</dv-timeline-calendar>

<dvlib-dialog
    [open]="copyStore.copyDialogOpen()"
    (dialogClose)="copyStore.copyDialogOpen.set(false)">
    <ng-template>
        <dv-personalplanung-copy-form
            [kinderOrt]="store.kinderOrt()!"
            [sourceVon]="timelineStore.startDate()"
            [sourceBis]="timelineStore.endDate()"
            (copyZuweisungen)="store.copyZuweisungen($event)"
            (cancel)="copyStore.copyDialogOpen.set(false)"/>
    </ng-template>
</dvlib-dialog>

<dvlib-dialog
    [open]="store.copyStore.copyConflictDialogOpen()"
    (dialogClose)="closeConflicDialog()">
    <ng-template>
        <div class="modal-body">
            <h1 [innerHTML]="'PERSONAL.ZUWEISUNG.COPY_CONFLICT_TITLE' | translate"></h1>
            <h2 [innerHTML]="'PERSONAL.ZUWEISUNG.COPY_CONFLICT_SUBTITLE' | translate"></h2>
        </div>
        <div class="modal-footer big-abstand-top">
            <dvlib-button-list>
                <dvlib-loading-button
                    type="submit"
                    [disabled]="copyStore.copyLoading()"
                    [isLoading]="copyStore.copyLoading()"
                    (clickEvent)="store.copyWithConflict(false)">
                    {{ 'PERSONAL.ZUWEISUNG.COPY_CONFLICT_KEEP' | translate }}
                </dvlib-loading-button>
                <button
                    type="button"
                    class="btn btn-link"
                    [disabled]="copyStore.copyLoading()"
                    (click)="store.copyWithConflict(true)">
                    {{ 'PERSONAL.ZUWEISUNG.COPY_CONFLICT_DELETE' | translate }}
                </button>
                <button
                    type="button"
                    class="btn btn-link"
                    [translate]="'COMMON.ABBRECHEN'"
                    (click)="closeConflicDialog()">
                </button>
            </dvlib-button-list>
        </div>
    </ng-template>
</dvlib-dialog>

<dvlib-dialog [open]="emailStore.dialogOpen()" (dialogClose)="emailStore.dialogOpen.set(false)">
    <ng-template>
        <dv-personalplanung-email-form
            [kinderOrt]="store.kinderOrt()!"
            [gueltigAb]="timelineStore.startDate()"
            [gueltigBis]="timelineStore.endDate()"
            (cancel)="emailStore.dialogOpen.set(false)"/>
    </ng-template>
</dvlib-dialog>

<dv-termin-form-dialog
    [termin]="terminStore.newTermin"
    [dialogOpen]="terminStore.terminDialog().open"
    [terminTypes]="terminStore.terminTypes()"
    (save)="terminStore.addOrUpdateTermin($event.termin)"
    (cancel)="terminStore.terminDialog.set({open: false})">
</dv-termin-form-dialog>

<dvlib-dialog
    [open]="terminStore.terminUpdateModeDialog().open"
    (dialogClose)="terminStore.closeUpdateModeDialog()">
    <ng-template>
        <dv-termin-update-mode-dialog
            [title]="terminStore.terminUpdateModeDialog().title!"
            [submitLabel]="terminStore.terminUpdateModeDialog().submitLabel"
            (submitMode)="terminStore.submitUpdateMode($event)"
            (cancel)="terminStore.closeUpdateModeDialog()">
        </dv-termin-update-mode-dialog>
    </ng-template>
</dvlib-dialog>

<dvlib-dialog
    [open]="terminStore.terminDeleteDialog().open"
    (dialogClose)="terminStore.closeTerminDeleteDialog()">
    <ng-template>
        <dv-termin-delete-dialog
            [dialogData]="terminStore.terminDeleteDialog()"
            (delete)="terminStore.deleteTermin($event)"
            (cancel)="terminStore.closeTerminDeleteDialog()">
        </dv-termin-delete-dialog>
    </ng-template>
</dvlib-dialog>

<dv-overlay
    [open]="store.editTimesStore.open()"
    [connectedTo]="store.editTimesStore.target()"
    (closeOverlay)="store.editTimesStore.closeOverlay()">
    <ng-template>
        <dv-zuweisung-edit-time-form
            [event]="store.editTimesStore.calendarEvent()!"
            (updateTimes)="store.updateZuweisungZeitWithTimes($event)"
            (editFinished)="store.editTimesStore.closeOverlay()">
        </dv-zuweisung-edit-time-form>
    </ng-template>
</dv-overlay>

<dvlib-dialog
    [open]="store.personalSortDialog().open"
    (dialogClose)="store.closePersonalSortDialog()">
    <ng-template>
        @if (store.personalSortDialog().group; as group) {
            <dv-personal-sort-dialog
                [group]="group"
                [sortOrder]="store.personalSortDialog().sortOrder!"
                [angestellte]="(store.angestellte$ | async) || []"
                (submitOrder)="store.updateSortOrder($event)"
                (cancel)="store.closePersonalSortDialog()">
            </dv-personal-sort-dialog>
        }
    </ng-template>
</dvlib-dialog>

<dvlib-confirm-dialog
    [open]="store.saveControllingDataDialogOpen()"
    [title]="'PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING' | translate"
    [subtitle]="'PERSONAL.ZUWEISUNG.CONVERT_TO_CONTROLLING_INFO' | translate:{range: timelineStore.displayRange()}"
    [isLoading]="store.controllingDataSaveIsLoading()"
    [confirmActionText]="'COMMON.BESTAETIGEN' | translate"
    [cancelActionText]="'COMMON.ABBRECHEN' | translate"
    (confirm)="store.convertForControlling()"
    (cancel)="store.saveControllingDataDialogOpen.set(false)"/>
