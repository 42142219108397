{
  "name": "@dv/kitadmin",
  "version": "2.61.0",
  "description": "Ein Tool zur Verwaltung von Kindern & Kinderorten",
  "homepage": "https://intra.dvbern.ch/display/KIT/kitAdmin",
  "repository": {
    "type": "git",
    "url": "https://gitlab.dvbern.ch/kitadmin/kita-scheduler-gui"
  },
  "engine": {
    "node": ">=20.11.1",
    "npm": ">=10.2.5"
  },
  "bugs": {
    "url": "https://support.dvbern.ch/browse/KIT"
  },
  "author": {
    "name": "DV Bern AG",
    "url": "https://www.dvbern.ch/"
  },
  "scripts": {
    "start": "nx serve kit-admin",
    "start:public": "nx serve kit-admin --host 0.0.0.0",
    "start:remote": "nx serve kit-admin --proxy-config ./config/proxy.conf.kita-dev.json",
    "start:offset": "nx serve kit-admin --proxy-config ./config/proxy.conf.port-offset.json",
    "start-groupAdmin": "nx serve group-admin",
    "start-groupAdmin:remote": "nx serve group-admin --proxy-config ./config/proxy.conf.kita-dev.json",
    "prepush": "concurrently -m 1 npm:lint npm:test:all",
    "build:all": "nx run-many --target build --all",
    "build:apps:kitAdmin": "nx build kit-admin",
    "build:apps:groupAdmin": "nx build group-admin",
    "analyze:bundle:kitAdmin": "npx source-map-explorer dist/apps/kit-admin/*.js",
    "analyze:bundle:groupAdmin": "npx source-map-explorer dist/apps/group-admin/*.js",
    "clean": "npm run clean:dist && npm run clean:build && npm run clean:target",
    "clean:dist": "rimraf dist",
    "clean:build": "rimraf build",
    "clean:target": "rimraf target",
    "clean:cache": "rimraf .angular/cache .cache .nx/cache .swc",
    "test:watch:kitAdmin": "nx run kit-admin-lib:test:watch",
    "test:all": "nx run-many -t test",
    "test:sonar": "cross-env TEST_SONAR=true nx run-many -t test --skip-nx-cache -- --code-coverage",
    "lint": "concurrently -- npm:lint:eslint npm:lint:tsc npm:lint:style",
    "lint:eslint": "nx run-many -t lint",
    "lint:eslint:profile": "TIMING=1 npx run-many -t lint",
    "lint:tsc": "nx run-many -t type-check",
    "lint:style": "nx run-many -t stylelint",
    "lint:bootstrap": "npx bootlint -d W001,W002,W003,W005,W009,E001,E003,E013,E014,E032 src/**/*.html",
    "e2e": "npm run e2e:test:all:local",
    "e2e:test:all:local": "concurrently -P --maxProcesses=1 --group 'npm:e2e:test:project:* -- {@}' --",
    "e2e:test:all:kitadev": "cross-env KITADMIN_URL=\"https://dev-kitadmin.dvbern.ch/\" GROUPADMIN_URL=\"https://dev-groupadmin-kitadmin.dvbern.ch/\" concurrently -P --maxProcesses=1 --group 'npm:e2e:test:project:* -- {@}' --",
    "e2e:test:setup:kitAdmin": "cross-env PRJ=kitAdmin playwright test --project=kitAdmin-setup",
    "e2e:test:setup:groupAdmin": "cross-env PRJ=groupAdmin playwright test --project=groupAdmin-setup",
    "e2e:test:project:kitAdmin": "cross-env PRJ=kitAdmin playwright test --project=kitAdmin",
    "e2e:test:project:kitAdmin_faktura_tarif": "cross-env PRJ=kitAdmin_faktura_tarif playwright test --project=kitAdmin_faktura_tarif --workers=1",
    "e2e:test:project:groupAdmin": "cross-env PRJ=groupAdmin playwright test --project=groupAdmin",
    "e2e:update-snapshots:local": "npm run e2e:test:all:local -- --update-snapshots",
    "e2e:update-snapshots:kitadev": "npm run e2e:test:all:kitadev -- --update-snapshots",
    "security:check": "ts-node ./scripts/security-check.ts",
    "lighthouse": "npx lighthouse https://dev-kitadmin.dvbern.ch --disable-device-emulation --output html --output-path ./build/lighthouse-report.html",
    "openapi": "ts-node scripts/openapi-build.ts",
    "openapi-extract-current-templates": "openapi-generator-cli author template -g typescript-angular --output scripts/conf/openapi-templates/default-7.5.0"
  },
  "license": "UNLICENSED",
  "private": true,
  "dependencies": {
    "@angular-architects/ngrx-toolkit": "~18.0.3",
    "@angular/animations": "~18.2.4",
    "@angular/cdk": "~18.2.4",
    "@angular/common": "~18.2.4",
    "@angular/compiler": "~18.2.4",
    "@angular/core": "~18.2.4",
    "@angular/elements": "~18.2.4",
    "@angular/forms": "~18.2.4",
    "@angular/platform-browser": "~18.2.4",
    "@angular/platform-browser-dynamic": "~18.2.4",
    "@angular/router": "~18.2.4",
    "@angular/upgrade": "~18.2.4",
    "@honoluluhenk/http-param-expander": "~1.0.1",
    "@ngrx/operators": "^18.0.1",
    "@ngrx/signals": "~18.0.2",
    "@ngx-translate/core": "~15.0.0",
    "@ngxs/devtools-plugin": "~18.1.1",
    "@ngxs/logger-plugin": "~18.1.1",
    "@ngxs/router-plugin": "~18.1.1",
    "@ngxs/storage-plugin": "~18.1.1",
    "@ngxs/store": "~18.1.1",
    "@sentry/angular-ivy": "~7.114.0",
    "@state-adapt/angular": "~2.0.8",
    "@state-adapt/core": "~2.0.8",
    "@state-adapt/rxjs": "~2.0.8",
    "@tinymce/tinymce-angular": "~8.0.1",
    "@uirouter/angular": "^14.0.0",
    "@uirouter/angular-hybrid": "^18.0.0",
    "@uirouter/angularjs": "^1.1.1",
    "@uirouter/core": "^6.1.1",
    "@uirouter/rx": "^1.0.0",
    "angular": "~1.8.3",
    "angular-animate": "~1.8.3",
    "angular-cookies": "~1.8.3",
    "angular-elastic": "~2.5.1",
    "angular-i18n": "~1.8.3",
    "angular-moment": "~1.3.0",
    "angular-oauth2-oidc": "~17.0.2",
    "angular-sanitize": "~1.8.3",
    "angular-toarrayfilter": "~1.0.3",
    "angular-translate": "~2.19.1",
    "angular-translate-handler-log": "~2.19.1",
    "angular-translate-interpolation-messageformat": "~2.19.1",
    "angular-translate-storage-cookie": "~2.19.1",
    "angular-translate-storage-local": "~2.19.1",
    "angular-ui-bootstrap": "~2.5.6",
    "angular-ui-scroll": "~1.9.1",
    "angular-ui-tree": "~2.22.6",
    "bootstrap": "~3.4.1",
    "bootstrap-sass": "~3.4.3",
    "bootstrap5": "npm:bootstrap@~5.2.3",
    "broadcast-channel": "~4.13.0",
    "chart.js": "~2.9.4",
    "comparators": "~3.0.5",
    "core-js": "~3.38.1",
    "font-awesome": "~4.7.0",
    "immer": "~10.1.1",
    "jquery": "~3.7.1",
    "moment": "~2.30.1",
    "ng-file-upload": "~12.2.13",
    "ngx-bootstrap": "~9.0.0",
    "ngx-colors": "~3.5.3",
    "ngx-translate-messageformat-compiler": "~7.0.0",
    "ngx-ui-scroll": "~3.2.0",
    "rrule": "~2.8.1",
    "rxjs": "~7.8.1",
    "subsink": "~1.0.2",
    "thenby": "~1.3.4",
    "tinymce": "~6.8.4",
    "tslib": "~2.7.0",
    "vscroll": "~1.6.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-builders/custom-webpack": "~18.0.0",
    "@angular-devkit/build-angular": "~18.2.3",
    "@angular-devkit/core": "~18.2.3",
    "@angular-devkit/schematics": "~18.2.3",
    "@angular-eslint/builder": "~18.3.0",
    "@angular-eslint/eslint-plugin": "~18.3.0",
    "@angular-eslint/eslint-plugin-template": "~18.3.0",
    "@angular-eslint/schematics": "~18.3.0",
    "@angular-eslint/template-parser": "~18.3.0",
    "@angular/cli": "~18.2.3",
    "@angular/compiler-cli": "~18.2.4",
    "@angular/language-service": "~18.2.4",
    "@casualbot/jest-sonar-reporter": "~2.4.0",
    "@jscutlery/swc-angular": "~0.14.14",
    "@jscutlery/swc-angular-plugin": "~0.14.14",
    "@messageformat/core": "~3.3.0",
    "@nx/angular": "19.7.3",
    "@nx/devkit": "19.7.3",
    "@nx/eslint": "19.7.3",
    "@nx/eslint-plugin": "19.7.3",
    "@nx/jest": "19.7.3",
    "@nx/js": "19.7.3",
    "@nx/plugin": "19.7.3",
    "@nx/workspace": "19.7.3",
    "@onslip/karma-playwright-launcher": "~0.3.0",
    "@openapitools/openapi-generator-cli": "~2.13.5",
    "@playwright/test": "~1.47.0",
    "@schematics/angular": "~18.2.3",
    "@swc-node/register": "1.9.2",
    "@swc/core": "1.5.29",
    "@swc/helpers": "~0.5.13",
    "@swc/jest": "0.2.36",
    "@testing-library/angular": "~17.3.1",
    "@testing-library/jasmine-dom": "~1.3.3",
    "@testing-library/jest-dom": "~6.5.0",
    "@testing-library/user-event": "~14.5.2",
    "@types/angular": "~1.8.9",
    "@types/angular-mocks": "~1.7.4",
    "@types/angular-translate": "~2.19.4",
    "@types/angular-ui-bootstrap": "~1.0.7",
    "@types/angular-ui-scroll": "~1.3.38",
    "@types/chart.js": "~2.9.41",
    "@types/fs-extra": "~11.0.4",
    "@types/jasmine": "~5.1.4",
    "@types/jasminewd2": "~2.0.13",
    "@types/jest": "~29.5.13",
    "@types/jquery": "~3.5.30",
    "@types/lodash": "~4.17.7",
    "@types/ng-file-upload": "~12.2.8",
    "@types/node": "~20.14.11",
    "@types/testing-library__jasmine-dom": "~1.3.3",
    "@types/webpack": "~5.28.5",
    "@types/webpack-env": "~1.18.5",
    "@typescript-eslint/eslint-plugin": "7.17.0",
    "@typescript-eslint/parser": "7.17.0",
    "@typescript-eslint/types": "~7.17.0",
    "@typescript-eslint/utils": "^7.17.0",
    "@uirouter/visualizer": "~7.2.1",
    "angular-mocks": "~1.8.3",
    "angular-translate-quality": "~1.3.0",
    "assert": "~2.1.0",
    "concurrently": "~9.0.0",
    "copyfiles": "~2.4.1",
    "cross-env": "~7.0.3",
    "dotenv": "~16.4.5",
    "eslint": "~8.57.0",
    "eslint-plugin-header": "~3.1.1",
    "eslint-plugin-import": "~2.30.0",
    "eslint-plugin-jasmine": "~4.2.1",
    "eslint-plugin-jest": "~28.8.3",
    "eslint-plugin-jsdoc": "~48.7.0",
    "eslint-plugin-playwright": "~1.6.2",
    "eslint-plugin-prefer-arrow": "~1.2.3",
    "eslint-plugin-rxjs": "~5.0.3",
    "eslint-plugin-sonarjs": "~1.0.4",
    "eslint-plugin-testing-library": "~6.3.0",
    "eslint-plugin-unicorn": "~54.0.0",
    "fs-extra": "~11.2.0",
    "html-loader": "~5.1.0",
    "jasmine-core": "~5.3.0",
    "jasmine-reporters": "~2.5.2",
    "jasmine-spec-reporter": "~7.0.0",
    "jest": "29.7.0",
    "jest-environment-jsdom": "29.7.0",
    "jest-junit": "~16.0.0",
    "jest-preset-angular": "~14.2.2",
    "jest-sonar-reporter": "~2.0.0",
    "jsonc-eslint-parser": "~2.4.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "~2.0.1",
    "karma-mocha-reporter": "~2.2.5",
    "karma-sonarqube-unit-reporter": "~0.0.23",
    "mkdirp": "~3.0.1",
    "ng-packagr": "~18.2.1",
    "nx": "19.7.3",
    "nx-stylelint": "~17.1.5",
    "playwright": "~1.47.0",
    "prettier": "~2.8.8",
    "rimraf": "~6.0.1",
    "stylelint": "~16.9.0",
    "stylelint-config-standard": "~36.0.1",
    "stylelint-config-standard-scss": "~13.1.0",
    "stylelint-scss": "~6.5.1",
    "ts-jest": "~29.2.5",
    "ts-morph": "~23.0.0",
    "ts-node": "~10.9.2",
    "typescript": "~5.5.4",
    "webpack-bundle-analyzer": "~4.10.2"
  },
  "overrides": {
    "ngx-bootstrap": {
      "@angular/animations": "^18.2.0",
      "@angular/common": "^18.2.0",
      "@angular/core": "^18.2.0",
      "@angular/forms": "^18.2.0"
    },
    "@sentry/angular-ivy": {
      "@angular/common": "^18.2.0",
      "@angular/router": "^18.2.0",
      "@angular/core": "^18.2.0"
    },
    "@ngxs/storage-plugin": {
      "ts-morph": "^23.0.0"
    },
    "angular-translate-interpolation-messageformat": {
      "messageformat": "npm:@messageformat/core@^3.2.0",
      "messageformat-parser": "npm:@messageformat/parser@^5.1.0"
    },
    "@uirouter/visualizer": {
      "d3-interpolate": "3.0.1",
      "d3-hierarchy": "3.1.2"
    },
    "sourcemap-codec": "npm:@jridgewell/sourcemap-codec@^1.4.14",
    "intl-messageformat-parser": "npm:@formatjs/icu-messageformat-parser@^2.6.0",
    "@wessberg/ts-evaluator": "npm:ts-evaluator@^1.2.0"
  }
}
