/*
 * Copyright © 2019 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CONTROL_STATE} from '@dv/kitadmin/work-time-controlling';
import type {AuthStore} from '@dv/shared/angular';
import type {IPrincipal} from '@dv/shared/authentication/model';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {FunctionType} from '@dv/shared/code';
import {UserRole} from '@dv/shared/roles';
import type {StateService, TransitionService} from '@uirouter/core';
import type angular from 'angular';
import {BASE_CONFIGURATION_STATE} from '../../../communication/communication-states';
import {DASHBOARD_STATE} from '../../../dashboard/dashboard-state';
import {DATA_INTEGRITY_PHONE_NUMBERS_STATE} from '../../../data-integrity/data-integrity-states';
import {ANFRAGEN_STATE} from '../../../kibon/kibon-states';
import {ANSTELLUNG_STATES} from '../../../personal/anstellung/anstellung-states';
import {PERSONAL_STATES} from '../../../personal/personal-states';

export const COMPONENT_CONFIG: angular.IComponentOptions = {
    transclude: false,
    bindings: {
        principal: '<',
        logoutHandler: '&',
    },
    template: require('./dvb-navigation.html'),
    controllerAs: 'vm',
};

class DvbNavigation implements angular.IController {
    public static $inject: readonly string[] = ['$transitions', 'authStore', '$state'];
    public readonly supportTeamViewer: string = 'https://get.teamviewer.com/dvbv11';

    public principal!: IPrincipal;
    public logoutHandler?: FunctionType;

    public isNavCollapsed: boolean = true;
    public displayName: string = '';

    public hasMandantOrUserCreatePrivileges: boolean = false;
    public hasKitaViewPrivileges: boolean = false;

    public stundenerfassungRef: string | null = null;
    public zuweisungRef: string | null = null;

    public readonly personalStates = PERSONAL_STATES;
    public readonly anstellungStates = ANSTELLUNG_STATES;
    public readonly dataIntegrityState = DATA_INTEGRITY_PHONE_NUMBERS_STATE;
    public readonly kibonState = ANFRAGEN_STATE;
    public readonly communicationState = BASE_CONFIGURATION_STATE;
    public readonly workTimeControllingState = CONTROL_STATE;

    public constructor(
        private readonly $transitions: TransitionService,
        private readonly authStore: AuthStore,
        private readonly $state: StateService,
    ) {
    }

    public $onInit(): void {
        this.$transitions.onStart({}, () => {
            // automatically collapse the menu when changing the view state
            this.isNavCollapsed = true;
        });
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        const principal: angular.IChangesObject<IPrincipal> = onChangesObj.principal;
        if (!principal) {
            return;
        }

        const current = principal.currentValue;
        const isSupportAdmin = this.authStore.hasRole(UserRole.SUPPORT_ADMIN)
            && !this.authStore.hasRole(UserRole.MANDANT_ADMIN);

        this.displayName = isSupportAdmin ? `${current.mandantName} Support` : current.displayName ?? '';

        this.hasMandantOrUserCreatePrivileges = this.authStore.hasPermission(PERMISSION.SHARED.MANAGE_USERS_ANY)
            || this.authStore.hasRole(UserRole.MANDANT_ADMIN);
        this.hasKitaViewPrivileges = this.authStore.hasPermission(PERMISSION.KITA.VIEW_ANY);

        this.initAngestellteRefs(current);
    }

    public getLogoStateLink(): string {
        return this.authStore.hasPermission(PERMISSION.SHARED.EMPLOYEE_VIEW_ANY) ?
            this.$state.href(DASHBOARD_STATE.name) :
            '';
    }

    private initAngestellteRefs(principal?: IPrincipal): void {
        this.stundenerfassungRef = null;
        this.zuweisungRef = null;

        if (principal?.angestellteId) {
            this.zuweisungRef = this.$state.href(this.anstellungStates.ZUWEISUNG_STATE.name,
                {id: principal.angestellteId});
        }

        if (!principal?.hasStundenErfassung) {
            return;
        }

        this.stundenerfassungRef = this.$state.href(this.anstellungStates.STUNDENERFASSUNG_STATE.name,
            {id: principal.angestellteId});
    }
}

COMPONENT_CONFIG.controller = DvbNavigation;
