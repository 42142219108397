<div class="table-header">
    <div class="row">
        <div class="col-xs-6">{{ 'COMMON.POSITION' | translate }}</div>
        <div class="col-xs-2 text-right">{{ 'COMMON.ANZAHL' | translate }}</div>
        <div class="col-xs-2 text-right">{{ 'COMMON.BETRAG' | translate }}</div>
        <div class="col-xs-2 text-right">{{ 'COMMON.TOTAL' | translate }}</div>
    </div>
</div>

<div class="detail small-abstand-top">
    @if (rechnung().rechnungsPositionen.length === 0) {
        <div class="small-abstand-top">{{ 'ADMINISTRATION.KEINE_RECHNUNGS_POSITIONEN' | translate }}</div>
    }
    @if ((rechnung().kitaCount || 0) > 0) {
        <div class="small-abstand-top">
            <strong>
                {{
                    'ADMINISTRATION.VERRECHENBAR_KINDERORT.MF' | translate: {
                        count: rechnung().kitaCount,
                        showcount: true
                    }
                }}
            </strong>
        </div>
    }
    @for (position of kitaPositions(); track position) {
        <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
    }

    @if (kitaPositionsNichtVerrechenbar().length > 0) {
        <div class="small-abstand-top">
            <strong>
                {{
                    'ADMINISTRATION.NICHT_VERRECHENBAR_KINDERORT.MF' | translate:{
                        count: kitaPositionsNichtVerrechenbar().length,
                        showcount: true
                    }
                }}
            </strong>
        </div>
    }
    @for (position of kitaPositionsNichtVerrechenbar(); track position.id) {
        <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
    }

    @if ((rechnung().tageselternCount || 0) > 0) {
        <div class="small-abstand-top">
            <strong>
                {{ 'COMMON.TAGES_ELTERN.MF' | translate: {count: rechnung().tageselternCount, showcount: true} }}
            </strong>
        </div>
    }
    @for (position of tageselternPosisions(); track position) {
        <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position"/>
    }

    @if (rechnung().customPositionen.length > 0 || formContext.editMode) {
        <div class="small-abstand-top">
            <strong>{{ 'ADMINISTRATION.CUSTOM_RECHNUNG_POSITION' | translate }}</strong>
        </div>
    }
    @if (!formContext.editMode) {
        @for (position of customPositions(); track position.id) {
            <dv-administration-mandant-rechnungs-position [rechnungsPosition]="position" (onDelete)="delete(position)"/>
        }

        <div class="small-abstand-top">
            <button type="button" class="btn btn-primary" (click)="formContext.enableEditMode()">
                {{ 'ADMINISTRATION.CUSTOM_RECHNUNG_POSITIONEN_ANPASSEN' | translate }}
            </button>
        </div>
    } @else {
        <div class="small-abstand-top">
            <dv-administration-custom-rechnung-position-form
                [existingCustomPositionen]="rechnung().customPositionen"
                [formContext]="formContext"
                [positionType]="'mandantCustomPosition'"
                (onCancel)="formContext.close()"
                (onUpdate)="saveAndUpdatePositionen($event.customPositionen)"/>
        </div>
    }
</div>
