import {ChangeDetectionStrategy, Component, computed, inject, Input, signal, WritableSignal} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColorMode} from '@dv/kitadmin/models';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgClass, NgStyle} from '@angular/common';

@Component({
    selector: 'dv-pensum-icon',
    standalone: true,
    imports: [
        TooltipModule,
        NgClass,
        NgStyle,
    ],
    templateUrl: './pensum-icon.component.html',
    styleUrls: ['./pensum-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PensumIconComponent {
    @Input({required: true}) public value!: string;

    private tooltipInputText: WritableSignal<string | null> = signal(null);
    private isTooltipTranslated: WritableSignal<boolean> = signal(false);

    @Input()
    public set tooltip(t: string) {
        this.tooltipInputText.set(t);
    }

    @Input()
    public set tooltipTranslated(t: boolean) {
        this.isTooltipTranslated.set(t);
    }

    @Input() public inverted: boolean = false;
    @Input() public tooltipPlacement: string = 'top-left';
    @Input() public hide!: boolean;
    @Input() public colorMode!: ColorMode;

    public tooltipText = computed(() => {
        const text = this.tooltipInputText();
        const translated = this.isTooltipTranslated();

        if (text === null || translated) {
            return text ?? '';
        }

        return this.translateService.instant(text);
    });

    private translateService = inject(TranslateService);
}
