<div class="dvb-search-get-single-entity">
    <div class="form-row" ng-show="vm.ngModel.$viewValue || vm.readOnly">
        <div class="selected-entity" ng-class="{'row': !vm.asCol}">
            <div class="col-xs-8 entity">
                <dv-search-result-icon [entry]="vm.ngModel.$viewValue"></dv-search-result-icon>
                <span data-translate="{{vm.displayLabel}}"></span>
                <span ng-bind="vm.ngModel.$viewValue.text"></span>
            </div>
            <div class="col-xs-4 edit">
                <a href=""
                   ng-if="!vm.readOnly"
                   ng-click="vm.onRemove()"
                   data-translate="COMMON.LOESCHEN">
                </a>
            </div>
        </div>
    </div>
    <dvb-search-entity entity-to-search="{{vm.entityToSearch}}"
                       expand-entity="vm.expandEntity"
                       filter-source="vm.filterSource({$source: $source})"
                       on-select="vm.onSelect(item)"
                       on-select-clear="true"
                       disabled-entries="vm.disabledEntries"
                       ng-show="!vm.ngModel.$viewValue && !vm.readOnly"
                       placeholder-key="{{vm.placeholderKey}}"
                       placeholder-translated="vm.placeholderTranslated"
                       mandant-filter="vm.mandantFilter">
    </dvb-search-entity>
</div>
