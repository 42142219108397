@if (entry && entry.symbol) {
    <dv-pensum-icon
        [value]="entry.symbol"
        [tooltip]="title()"
        [tooltipTranslated]="true"
        [inverted]="entry.entity !== 'FIRMENKONTINGENT'">
    </dv-pensum-icon>
}

@if (entry && !entry.symbol) {
    <i class="fa"
       [ngClass]="iconClass()"
       [tooltip]="title()"
       container="body">
    </i>
}
