import {AsyncPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RequirePermissionStructuralDirective, RequireRoleAttributeDirective} from '@dv/shared/angular';
import {MandantConfigService} from '@dv/shared/backend/api/mandant-config.service';
import {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import {WorkTimeModelService} from '@dv/shared/backend/api/work-time-model.service';
import {JaxMandantConfig} from '@dv/shared/backend/model/jax-mandant-config';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {map} from 'rxjs';

@Component({
    selector: 'dv-mandant-config',
    standalone: true,
    imports: [
        TranslateModule,
        FormsModule,
        AsyncPipe,
        TooltipModule,
        RequirePermissionStructuralDirective,
        RequireRoleAttributeDirective,
    ],
    templateUrl: './mandant-config.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MandantConfigComponent {

    private readonly mandantConfigService = inject(MandantConfigService);
    private readonly workTimeModelService = inject(WorkTimeModelService);
    private readonly terminTypeService = inject(TerminTypeService);

    public config$ = this.mandantConfigService.get$();
    public workTimeModels$ = this.workTimeModelService.getAll$().pipe(
        map(data => data.models),
    );
    public terminTypes$ = this.terminTypeService.getAll$().pipe(
        map(data => data.items),
    );

    public updateConfig(config: JaxMandantConfig): void {
        this.mandantConfigService.update$({jaxMandantConfig: config})
            .subscribe();
    }
}
