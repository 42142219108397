<h2 [translate]="titleKey()"></h2>

<form #form="ngForm" (ngSubmit)="submitForm(form)">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   name="name"
                   [(ngModel)]="name"
                   [maxlength]="255"
                   [placeholder]="'PERSONAL.TERMIN.TYPE.NAME' | translate"
                   [tooltip]="'PERSONAL.TERMIN.TYPE.NAME' | translate"
                   [attr.aria-label]="'PERSONAL.TERMIN.TYPE.NAME' | translate">
        </div>
    </div>
    <div class="row normal-abstand-bottom">
        <div class="col-md-6">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE_TITLE' | translate }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               name="relevantForWorkTimeControlling"
                               value="true"
                               [(ngModel)]="relevantForWorkTimeControlling">
                        {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANT' | translate }}
                    </label>
                </div>
                @if (relevantForWorkTimeControlling()) {
                    <fieldset [attr.aria-label]="'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE_LABEL' | translate">
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       name="workTimeType"
                                       [(ngModel)]="workTimeControllingRelevance"
                                       [value]="'WORK_TIME'">
                                {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE.WORK_TIME' | translate }}
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input type="radio"
                                       name="workTimeType"
                                       [(ngModel)]="workTimeControllingRelevance"
                                       [value]="'ABSENCE'">
                                {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE.ABSENCE' | translate }}
                            </label>
                        </div>
                    </fieldset>
                }
                @if (showAbsenceTypeSelection()) {
                    <select name="absenceType"
                            class="form-control normal-abstand-top"
                            [tooltip]="'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | translate"
                            [(ngModel)]="absenceTypeId">
                        <option [ngValue]="null"
                                selected>{{ 'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | translate }}
                        </option>
                        @for (type of absenceTypes(); track type.id) {
                            <option [ngValue]="type.id">{{ type.name }}</option>
                        }
                    </select>
                }
            </fieldset>
        </div>
        <div class="col-md-6">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.BEDARFSRECHNUNG_TITLE' | translate }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               name="deductFromBedarfsrechnung"
                               value="true"
                               [(ngModel)]="deductFromBedarfsrechnung">
                        {{ 'PERSONAL.TERMIN.TYPE.DEDUCT_FROM_BEDARFSRECHNUNG' | translate }}
                    </label>
                </div>
            </fieldset>
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()"/>
</form>
