import {DecimalPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
    DayOfWeekFormComponent,
    DvDayOfWeekFormatPipe,
    formViewProvider,
    RequirePermissionStructuralDirective,
} from '@dv/shared/angular';
import {isPresent} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DayOfWeekAnstellungen} from '../../../models/anstellung-form-model';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-angestellte-anstellung-sollzeit',
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        TooltipModule,
        DayOfWeekFormComponent,
        RequirePermissionStructuralDirective,
        DvDayOfWeekFormatPipe,
        DecimalPipe,
    ],
    templateUrl: './angestellte-anstellung-sollzeit.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    viewProviders: [formViewProvider],
})
export class AngestellteAnstellungSollzeitComponent implements OnInit {
    @Input({required: true}) public dailyAnstellungen!: { [day: string]: DayOfWeekAnstellungen };

    public sollZeitSumme: WritableSignal<number | null> = signal(null);

    public ngOnInit(): void {
        this.recalculateSumme();
    }

    public recalculateSumme(): void {

        const sollZeiten = Object.values(this.dailyAnstellungen)
            .filter(daily => isPresent(daily.sollzeit));

        if (sollZeiten.length < 1) {
            this.sollZeitSumme.set(null);

            return;
        }

        this.sollZeitSumme.set(sollZeiten.reduce((sum, anstellung) => sum + (anstellung.sollzeit ?? 0), 0));
    }
}
