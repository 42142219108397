/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {DecimalPipe, JsonPipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {formViewProvider, RequirePermissionStructuralDirective} from '@dv/shared/angular';
import {JaxReadWorkTimeModel} from '@dv/shared/backend/model/jax-read-work-time-model';
import {DvbDateUtil, isNullish} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SearchListComponent} from '../../../../../search/component/search-list/search-list.component';
import {AnstellungStandortFormModel} from '../../../models/anstellung-form-model';
import {
    AngestellteAnstellungSollzeitComponent,
} from '../angestellte-anstellung-sollzeit/angestellte-anstellung-sollzeit.component';
import {
    AngestellteAnstellungZeitenComponent,
} from '../angestellte-anstellung-zeiten/angestellte-anstellung-zeiten.component';

const ONE_HUNDRED = 100;

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dv-anstellung-standort-form',
    standalone: true,
    imports: [
        TooltipModule,
        FormsModule,
        SearchListComponent,
        TranslateModule,
        AngestellteAnstellungZeitenComponent,
        RequirePermissionStructuralDirective,
        AngestellteAnstellungSollzeitComponent,
        DecimalPipe,
        JsonPipe,
    ],
    templateUrl: './anstellung-standort-form.component.html',
    viewProviders: [formViewProvider],
})
export class AnstellungStandortFormComponent implements OnInit {
    public readonly today = DvbDateUtil.today();
    @Input({required: true}) public anstellungStandort!: AnstellungStandortFormModel;
    @Input({required: true}) public workTimeModels!: JaxReadWorkTimeModel[];

    public arbeitspensumProzent: WritableSignal<number | null> = signal(null);
    public selectedWorkTimeModelId: WritableSignal<string | undefined> = signal(undefined);
    private selectedWorkTimeModel = computed(() => {
        const selectedWorkTimeModelId = this.selectedWorkTimeModelId();

        return this.workTimeModels.find(m => m.id === selectedWorkTimeModelId);
    });

    public readonly workTimeModelSollzeitSumme = computed(() => {
        const selectedWorkTimeModel = this.selectedWorkTimeModel();
        const arbeitspensumProzent = this.arbeitspensumProzent();

        if (isNullish(selectedWorkTimeModel) || isNullish(arbeitspensumProzent)) {
            return null;
        }

        return selectedWorkTimeModel.weeklyHours * arbeitspensumProzent / ONE_HUNDRED;
    });

    public ngOnInit(): void {
        // set a default work time model for new anstellungsstandorte
        if (isNullish(this.anstellungStandort.id)) {
            this.anstellungStandort.workTimeModelId = this.workTimeModels?.length === 1 ?
                this.workTimeModels[0].id :
                this.workTimeModels.find(m => m.mandantDefault)?.id;
        }

        this.arbeitspensumProzent.set(this.anstellungStandort.arbeitspensumProzent);
        this.selectedWorkTimeModelId.set(this.anstellungStandort.workTimeModelId);
    }
}
