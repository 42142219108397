<div class="row">
    <div class="col-xs-6">
        <span>{{ rechnungsPosition.text }}</span>
        @if (isDeletable) {
            <button class="btn btn-link pull-right" (click)="onDelete.emit()">
                <i class="fa fa-window-close"></i>
            </button>
        }
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ 'ADMINISTRATION.VALUE_X' | translate:{count: rechnungsPosition.count} }}
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ 'COMMON.BETRAG_VALUE' | translate:{betrag: rechnungsPosition.cost | number:'1.2-2'} }}
    </div>
    <div class="col-xs-2 text-right text-nowrap">
        {{ 'COMMON.BETRAG_VALUE' | translate: {betrag: totalAmount | number: '1.2-2'} }}
    </div>
</div>
