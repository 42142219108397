@if (value) {
    <div class="circle"
         [ngClass]="{
                inverted,
                normal: colorMode === 'normal',
                bright: colorMode === 'bright',
                contrast: colorMode === 'contrast',
                guide: colorMode === 'guide'
            }"
         [tooltip]="tooltipText()">
        {{ value }}
    </div>
}
