/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass} from '@angular/common';
import type {OnChanges} from '@angular/core';
import {ChangeDetectionStrategy, Component, HostBinding, Input, signal} from '@angular/core';
import type {SearchResultEntry} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PensumIconComponent} from '../../../common/component/pensum-icon/pensum-icon.component';
import {getIconClass, getTitle} from './search-result-icon-helpers';

@Component({
    selector: 'dv-search-result-icon',
    standalone: true,
    imports: [TooltipModule, NgClass, PensumIconComponent],
    templateUrl: './search-result-icon.component.html',
    styleUrls: ['./search-result-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultIconComponent implements OnChanges {

    @Input() public entry: SearchResultEntry | null = null;

    @HostBinding('class.fa-fw') private readonly faIcon: boolean = true;

    public title = signal('');
    public iconClass = signal('');

    public constructor(
        private readonly translator: Translator,
    ) {
    }

    public ngOnChanges(): void {
        this.title.set(this.translator.instant(getTitle(this.entry)));
        this.iconClass.set(getIconClass(this.entry));
    }
}
