<h2 [translate]="model.id ? 'PERSONAL.TERMIN.EDIT' : 'PERSONAL.TERMIN.ERSTELLEN'"
    [translateParams]="{entity: 'PERSONAL.TERMIN.SINGULAR' | translate}"
    class="normal-abstand-bottom">
</h2>

<form #form="ngForm" (ngSubmit)="submitForm(form)" class="dvb-form" [dvlibValidator]="selectionValidator">

    <label class="sr-only" for="termin-form-title">{{ 'PERSONAL.TERMIN.NAME' | translate }}</label>
    <input
        type="text"
        id="termin-form-title"
        class="form-control"
        #titleInput
        name="title"
        [maxlength]="DB_SHORT_LENGTH"
        [(ngModel)]="model.title"
        [placeholder]="'PERSONAL.TERMIN.NAME' | translate"
        [tooltip]="'PERSONAL.TERMIN.NAME' | translate"
        triggers="mouseenter:mouseleave">

    <div class="row">
        <div class="col-md-12">
            <select class="form-control"
                    name="terminTypeId"
                    required
                    [(ngModel)]="model.terminTypeId"
                    [attr.aria-invalid]="model.terminTypeId === undefined"
                    [attr.aria-label]="'PERSONAL.TERMIN.TYPE.SINGULAR' | translate"
                    [tooltip]="'PERSONAL.TERMIN.TYPE.SINGULAR' | translate"
                    placement="top left">
                <option [ngValue]="undefined" disabled>{{ 'PERSONAL.TERMIN.TYPE.CHOOSE_TYPE' | translate }}</option>
                @for (option of terminTypes; track option.id) {
                    <option [ngValue]="option.id">
                        {{ option.name }}
                    </option>
                }
            </select>
        </div>
    </div>

    <div class="row">
        <dvlib-datepicker-textfield
            class="form-row col-md-6"
            inputId="termin-form-gueltig-ab"
            name="gueltigAb"
            required
            [(ngModel)]="model.gueltigAb"
            [maxDate]="model.gueltigBis"
            [placeholder]="'COMMON.GUELTIG_AB' | translate">
        </dvlib-datepicker-textfield>
        <dvlib-datepicker-textfield
            class="form-row col-md-6"
            inputId="termin-form-gueltig-bis"
            name="gueltigBis"
            [(ngModel)]="model.gueltigBis"
            [minDate]="model.gueltigAb"
            [placeholder]="'COMMON.GUELTIG_BIS' | translate">
        </dvlib-datepicker-textfield>
    </div>

    <div class="row">
        <div class="col-md-6">
            <label class="sr-only" for="termin-form-von">{{ 'PERSONAL.TERMIN.VON' | translate }}</label>
            <input
                type="time"
                class="form-control"
                id="termin-form-von"
                name="von"
                [(ngModel)]="model.von"
                validVon
                [dateStart]="model.gueltigAb"
                [dateEnd]="model.gueltigBis"
                [timeEnd]="model.bis"
                [placeholder]="'PERSONAL.TERMIN.VON' | translate"
                [tooltip]="'PERSONAL.TERMIN.VON' | translate" />
        </div>
        <div class="col-md-6">
            <label class="sr-only" for="termin-form-von">{{ 'PERSONAL.TERMIN.BIS' | translate }}</label>
            <input
                type="time"
                class="form-control"
                id="termin-form-bis"
                name="bis"
                [(ngModel)]="model.bis"
                validBis
                [dateStart]="model.gueltigAb"
                [dateEnd]="model.gueltigBis"
                [timeStart]="model.von"
                [placeholder]="'PERSONAL.TERMIN.BIS' | translate"
                [tooltip]="'PERSONAL.TERMIN.BIS' | translate" />
        </div>
    </div>

    <label class="sr-only" for="termin-form-bemerkung">{{ 'PERSONAL.TERMIN.BEMERKUNG' | translate }}</label>
    <textarea
        name="bemerkung"
        id="termin-form-bemerkung"
        [(ngModel)]="model.bemerkung"
        [maxlength]="DB_MAX_LENGTH"
        [placeholder]="'PERSONAL.TERMIN.BEMERKUNG' | translate"
        [tooltip]="'PERSONAL.TERMIN.BEMERKUNG' | translate"
        dvlibElasticTextInput>
    </textarea>

    <div class="row normal-abstand-bottom">
        <div class="col-md-6 layout-row flex-start items-center">
            <ngx-colors
                ngx-colors-trigger
                name="backgroundColor"
                [(ngModel)]="model.backgroundColor"
                [dvlibValidator]="colorValidator"
                colorPickerControls="no-alpha"
                tabindex="0"
                format="hex"
                [palette]="colorPalette"
                [tooltip]="'PERSONAL.TERMIN.BACKGROUND_COLOR' | translate"
                [acceptLabel]="'COMMON.AUSWAEHLEN' | translate"
                [cancelLabel]="'COMMON.ABBRECHEN' | translate">
            </ngx-colors>
            <span class="smaller-abstand-left"
                  [ngClass]="{'error-message': form.submitted && form.controls.backgroundColor.invalid}"
                  [translate]="'PERSONAL.TERMIN.BACKGROUND_COLOR'"></span>
        </div>
        <div class="col-md-6 layout-row flex-start items-center">
            <ngx-colors
                ngx-colors-trigger
                name="textColor"
                [(ngModel)]="model.textColor"
                [dvlibValidator]="colorValidator"
                colorPickerControls="no-alpha"
                tabindex="0"
                format="hex"
                [palette]="textColorPalette"
                [tooltip]="'PERSONAL.TERMIN.TEXT_COLOR' | translate"
                [acceptLabel]="'COMMON.AUSWAEHLEN' | translate"
                [cancelLabel]="'COMMON.ABBRECHEN' | translate">
            </ngx-colors>
            <span class="smaller-abstand-left"
                  [ngClass]="{'error-message': form.submitted && form.controls.textColor.invalid}"
                  [translate]="'PERSONAL.TERMIN.TEXT_COLOR'"></span>
        </div>
    </div>

    @if (selectedAngestellte$ | async; as selectedAngestellte) {
        <div class="checkbox">
            <label>
                <input
                    type="checkbox"
                    name="alleAngestellte"
                    [(ngModel)]="model.alleAngestellte"
                    (ngModelChange)="onAlleAngestellteChange(model.alleAngestellte)"
                    [dvlibSetValue]="selectedAngestellte.length === 0" />
                <span [translate]="'PERSONAL.ANGESTELLTE.ALLE'"></span>
            </label>
        </div>
        @if (showAngestellteSearch()) {
            <dv-search-list
                entityToSearch="ANGESTELLTE"
                name="selectedAngestellte"
                [(ngModel)]="model.selectedAngestellte"
                [dvlibSetValue]="selectedAngestellte"
                [disabled]="model.alleAngestellte === true"
                [required]="model.alleKinderOrte === false"
                [orderByText]="true"
                [placeholder]="'PERSONAL.ANGESTELLTE.AUSWAEHLEN' | translate">
            </dv-search-list>
        } @else {
            <p translate="PERSONAL.ANGESTELLTE.EXCLUDED"></p>
            <dv-search-list
                entityToSearch="ANGESTELLTE"
                name="selectedExcludedAngestellte"
                [(ngModel)]="model.selectedExcludedAngestellte"
                [dvlibSetValue]="(selectedExcludedAngestellte$ | async) || []"
                [disabled]="model.alleAngestellte === false"
                [orderByText]="true"
                [placeholder]="'PERSONAL.ANGESTELLTE.EXCLUDED_AUSWAEHLEN' | translate">
            </dv-search-list>
        }
    } @else {
        <dvlib-spinner [show]="true" />
    }

    @if (selectedKinderOrte$ | async; as kinderOrte) {
        <div class="checkbox">
            <label>
                <input
                    type="checkbox"
                    name="alleKinderOrte"
                    [(ngModel)]="model.alleKinderOrte"
                    (ngModelChange)="onAlleKinderOrteChange(model.alleKinderOrte)">
                <span [translate]="'COMMON.ALLE_KINDERORTE'"></span>
            </label>
        </div>

        @if (showKinderOrtSearch()) {
            <dv-search-list
                entityToSearch="KITA"
                name="selectedKinderOrte"
                [(ngModel)]="model.selectedKinderOrte"
                [dvlibSetValue]="kinderOrte"
                [disabled]="model.alleKinderOrte === true"
                [required]="model.alleKinderOrte === false"
                [orderByText]="true"
                [placeholder]="'COMMON.KINDERORT.AUSWAEHLEN' | translate">
            </dv-search-list>
        }
    } @else {
        <dvlib-spinner [show]="true" />
    }

    <div class="row">
        <div class="col-md-12">
            <div class="checkbox">
                <label>
                    <input type="checkbox" name="wiederkehrend" [(ngModel)]="model.wiederkehrend" />
                    <span [translate]="'PERSONAL.TERMIN.WIEDERKEHRENDE'"></span>
                </label>
            </div>
        </div>
    </div>

    @if (model.wiederkehrend) {
        <div class="form-section" ngModelGroup="rrule">
            <div class="row">
                <div class="col-md-4">
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                [(ngModel)]="rruleModel.frequenzTyp"
                                required
                                name="frequenzTyp"
                                [value]="frequenzyTypes.DAILY" />
                            <span [translate]="'PERSONAL.TERMIN.FREQUENZ_TYP.DAILY.TITLE'"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                [(ngModel)]="rruleModel.frequenzTyp"
                                required
                                name="frequenzTyp"
                                [value]="frequenzyTypes.WEEKLY" />
                            <span [translate]="'PERSONAL.TERMIN.FREQUENZ_TYP.WEEKLY.TITLE'"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="radio">
                        <label>
                            <input
                                type="radio"
                                [(ngModel)]="rruleModel.frequenzTyp"
                                required
                                name="frequenzTyp"
                                [value]="frequenzyTypes.MONTHLY" />
                            <span [translate]="'PERSONAL.TERMIN.FREQUENZ_TYP.MONTHLY.TITLE'"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="frequency">
                <div class="frequency-type">
                    <span [translate]="'PERSONAL.TERMIN.FREQUENZ_PREFIX'"></span>
                    <input
                        type="number"
                        class="form-control input-inline"
                        name="interval"
                        [(ngModel)]="rruleModel.interval"
                        [tooltip]="'PERSONAL.TERMIN.FREQUENZ_TYP.' + getFrequencyStr(rruleModel.frequenzTyp!) + '.INTERVAL' | translate"
                        required
                        min="1"
                        step="1" />
                    <span
                        [translate]="'PERSONAL.TERMIN.FREQUENZ_TYP.' + getFrequencyStr(rruleModel.frequenzTyp!) + '.INTERVAL'">
                    </span>
                </div>
                <div class="recurrence-end-choice">
                    <span class="recurrence-end-title"
                          [translate]="'PERSONAL.TERMIN.BIS_TYPE.TITLE'">
                    </span>
                    <div>
                        <div class="radio radio-row">
                            <label>
                                <input
                                    type="radio"
                                    name="bisType"
                                    [(ngModel)]="rruleModel.bisType"
                                    [value]="bisTypes.BIS_EWIG" />
                                <span [translate]="'PERSONAL.TERMIN.BIS_TYPE.BIS_EWIG'"></span>
                            </label>
                        </div>
                        <div class="radio radio-row">
                            <label>
                                <input
                                    type="radio"
                                    name="bisType"
                                    [(ngModel)]="rruleModel.bisType"
                                    [value]="bisTypes.BIS_DATUM" />
                                <span [translate]="'PERSONAL.TERMIN.BIS_TYPE.BIS_DATUM'"></span>
                            </label>
                            <dvlib-datepicker-textfield
                                name="endDate"
                                [(ngModel)]="rruleModel.endDateLocal"
                                [minDate]="model.gueltigBis || model.gueltigAb"
                                [required]="rruleModel.bisType === bisTypes.BIS_DATUM"
                                [placeholder]="'PERSONAL.TERMIN.BIS_TYPE.BIS_DATUM' | translate">
                            </dvlib-datepicker-textfield>
                        </div>
                        <div class="radio radio-row">
                            <label>
                                <input
                                    type="radio"
                                    name="bisType"
                                    [(ngModel)]="rruleModel.bisType"
                                    [value]="bisTypes.ANZAHL" />
                                <span [translate]="'PERSONAL.TERMIN.BIS_TYPE.ANZAHL'"></span>
                            </label>
                            <input
                                class="form-control input-inline"
                                type="number"
                                name="repetitions"
                                [(ngModel)]="rruleModel.repetitions"
                                [tooltip]="'PERSONAL.TERMIN.WIEDERHOLUNGEN' | translate"
                                [required]="rruleModel.bisType === bisTypes.ANZAHL"
                                min="1"
                                [max]="MAX_REPETITIONS"
                                step="1" />
                            <span [translate]="'PERSONAL.TERMIN.WIEDERHOLUNGEN'"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="normal-abstand-bottom">
                <p>{{ 'PERSONAL.TERMIN.EXCLUSIONS' | translate }}</p>
                @for (excluded of excludedDatesModel; track trackByIdentity(i, excluded); let i = $index) {
                    <div
                        class="row selected-entity">
                        <div class="col-xs-8">
                            <dvlib-backend-limited-with-optional-time [backendLimited]="excluded" />
                        </div>
                        <div class="col-xs-4 edit">
                            <button type="button" class="btn btn-link" (click)="removeExcludedDate(i)">
                                {{ 'COMMON.LOESCHEN' | translate }}
                            </button>
                        </div>
                    </div>
                }
                <div class="row small-abstand-top">
                    <div class="col-sm-4">
                        <dvlib-datepicker-textfield
                            name="exclusionAb"
                            [placeholder]="'COMMON.GUELTIG_AB' | translate"
                            [(ngModel)]="exclusionPickerModel.gueltigAb">
                        </dvlib-datepicker-textfield>
                    </div>
                    <div class="col-sm-4">
                        <dvlib-datepicker-textfield
                            name="exclusionBis"
                            [placeholder]="'COMMON.GUELTIG_BIS' | translate"
                            [(ngModel)]="exclusionPickerModel.gueltigBis">
                        </dvlib-datepicker-textfield>
                    </div>
                    <div class="col-sm-4 text-right">
                        <button
                            type="button"
                            class="btn btn-link form-linker"
                            (click)="addExcludedDate()">
                            {{ 'PERSONAL.TERMIN.ADD_EXCLUSION' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    <dv-submit-cancel-buttons [isLoading]="loadingState.isLoading()" on-cancel="cancel.emit()">
    </dv-submit-cancel-buttons>

</form>
