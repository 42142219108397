/*
 * Copyright © 2018 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {JsonPipe} from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    Output,
    signal,
} from '@angular/core';
import {KinderOrtType, MandantCustomPosition, MandantRechnung} from '@dv/kitadmin/models';
import {DialogService} from '@dv/kitadmin/ui';
import {handleResponse} from '@dv/shared/angular';
import {MandantRechnungCustomPositionService} from '@dv/shared/backend/api/mandant-rechnung-custom-position.service';
import {checkPresent, DvbDateUtil, DvbRestUtil, FormContext} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import moment from 'moment';
import {
    AdministrationCustomRechnungPositionFormComponent,
} from '../administration-custom-rechnung-position-form/administration-custom-rechnung-position-form.component';
import {FormPosition} from '../administration-custom-rechnung-position-form/form-position';
import {
    AdministrationMandantRechnungsPositionComponent,
} from '../administration-mandant-rechnungs-position/administration-mandant-rechnungs-position.component';

@Component({
    selector: 'dv-administration-mandant-rechnung-detail',
    standalone: true,
    imports: [
        TranslateModule,
        AdministrationMandantRechnungsPositionComponent,
        AdministrationCustomRechnungPositionFormComponent,
        JsonPipe,
    ],
    templateUrl: './administration-mandant-rechnung-detail.component.html',
    styleUrls: ['./administration-mandant-rechnung-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationMandantRechnungDetailComponent {

    @Input({required: true, alias: 'rechnung'})
    public set _rechnung(value: MandantRechnung) {
        this.rechnung.set(value);
    }

    @Input({required: true, alias: 'stichtag'})
    public set _stichtag(value: moment.Moment) {
        this.stichtag.set(value);
    }

    @Output() public readonly reload = new EventEmitter();

    public rechnung = signal<MandantRechnung>(new MandantRechnung());
    public stichtag = signal<moment.Moment>(moment());

    private readonly customPositionService = inject(MandantRechnungCustomPositionService);
    private readonly dialogService = inject(DialogService);

    public kitaPositions = computed(() => {
        return this.rechnung().rechnungsPositionen
            .filter(pos => pos.type === KinderOrtType.KITA && pos.count !== 0)
            .sort((a, b) => a.text!.localeCompare(b.text!));
    });

    public kitaPositionsNichtVerrechenbar = computed(() => {
        return this.rechnung().rechnungsPositionen
            .filter(position => position.type === KinderOrtType.KITA && position.count === 0 && position.cost !== 0)
            .sort((a, b) => a.text!.localeCompare(b.text!));
    });

    public tageselternPosisions = computed(() => {
        return this.rechnung().rechnungsPositionen
            .filter(pos => pos.type === KinderOrtType.TAGES_ELTERN)
            .sort((a, b) => a.text!.localeCompare(b.text!));
    });

    public customPositions = computed(() => {
        return this.rechnung().customPositionen.sort((a, b) => a.text!.localeCompare(b.text!));
    });

    public formContext: FormContext = new FormContext();

    public saveAndUpdatePositionen(customPositionen: FormPosition[]): void {
        const gueltigAb = DvbDateUtil.startOfMonth(moment(this.stichtag()));
        const gueltigBis = DvbDateUtil.endOfMonth(moment(this.stichtag()));
        const mandantId = checkPresent(this.rechnung().mandant?.id);
        this.formContext.startLoading();

        const mandantPositions = customPositionen.map(pos => {
            const original = pos.position as MandantCustomPosition;

            return new MandantCustomPosition(
                original.id,
                mandantId,
                pos.text,
                pos.count,
                pos.cost,
                gueltigAb,
                gueltigBis,
                original.type);
        });

        this.customPositionService.overrideWithNewCustomPostionen$({
            custompositions: {
                mandantId,
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(gueltigAb),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(gueltigBis),
            },
            jaxMandantCustomPositionen: {
                mandantCustomPositionen: mandantPositions.map(p => p.toRestObject()),
            },
        }).pipe(handleResponse({
            next: () => {
                this.reload.emit();
                this.formContext.close();
            },
            finalize: () => this.formContext.finishLoading(),
        })).subscribe();
    }

    public delete(customPosition: MandantCustomPosition): void {
        this.dialogService.openDeleteDialog({
            entityText: 'ADMINISTRATION.CUSTOM_RECHNUNG_POSITION',
            confirm: () => this.customPositionService.deleteMandantRechnungCustomPosition$({
                positionId: checkPresent(customPosition.id),
            }).pipe(handleResponse({
                    next: () => this.reload.emit(),
                }),
            ),
        });
    }
}
